import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/Form.css';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import countryCodes from '../data/countryCodes.json';
import countriesData from '../data/countries.json';
import Select from 'react-select';
import SimpleDatePicker from './SimpleDatePicker'
import { useTranslation } from 'react-i18next';
import { optionTranslations } from '../i18n';

const Formulario = () => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    relacion_jugador: '',
    nombre_contacto: '',
    apellido_contacto: '',
    segundo_apellido_contacto: '',
    codigo_area: '',
    numero_telefono: '',
    email: '',
    nombre_jugador: '',
    apellido_jugador: '',
    segundo_apellido_jugador: '',
    fecha_nacimiento: null,
    genero: '',
    tipo_jugador: '',
    interes_programa: '',
    fecha_inicio: null,
    idioma_preferido: '',
    pais_origen: '',
    como_conociste: '',
  });

  const areaOptions = countryCodes.map((country) => ({
    value: country.dial_code,
    label: `${country.code} (${country.dial_code})`
  }));
  
  
  const countryOptions = countriesData.map((country) => ({
    value: {
      countryCode: country.code,
      countryName: country.name
    },
    label: country.name
  }));
  
  const handleCountryChange = (selectedOption) => {
    setFormData({ 
      ...formData, 
      pais_origen: selectedOption.value
    });
  };


  const [optionsGenero, setOptionsGenero] = useState([]);
  const [optionsTipoJugador, setOptionsTipoJugador] = useState([]);
  const [optionsInteresPrograma, setOptionsInteresPrograma] = useState([]);
  const [optionsComoNosConociste, setOptionsComoNosConociste] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [optionsIdioma, setOptionsIdioma] = useState([]);

  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(true);

  const apiKey = process.env.REACT_APP_API_KEY;

  const translateOptions = (text) => {
    return optionTranslations[i18n.language][text] || text;
  };

  useEffect(() => {
    const query = `
    {
      boards(ids: 1479621461) {
        columns {
          id
          title
          type
          settings_str
        }
      }
    }
  `;

    fetch('https://api.monday.com/v2', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
      body: JSON.stringify({ query }),
    })
      .then((res) => res.json())
      .then((result) => {
        
        const columns = result.data.boards[0].columns;

        if (!columns) {
          throw new Error('No se encontraron las columnas en la respuesta');
        }      
  
        const columnStatus = columns.find(col => col.id === 'relaci_n_con_jugador5__1');
        if (columnStatus) {
          const settings = JSON.parse(columnStatus.settings_str);
          setOptions(Object.entries(settings.labels)
            .filter(([_, text]) => text !== '-')
            .map(([id, text]) => ({
              id,
              text: translateOptions(text),
              value: text
            })));
        }
        
        const columnGenero = columns.find(col => col.id === 'selecci_n__nica5__1');
        if (columnGenero) {
          const settings = JSON.parse(columnGenero.settings_str);
          setOptionsGenero(Object.entries(settings.labels)
            .filter(([_, text]) => text !== '-')
            .map(([id, text]) => ({
              id,
              text: translateOptions(text),
              value: text
            })));
        }
        
        const columnTipoJugador = columns.find(col => col.id === 'selecci_n__nica9__1');
        if (columnTipoJugador) {
          const settings = JSON.parse(columnTipoJugador.settings_str);
          setOptionsTipoJugador(Object.entries(settings.labels)
          .filter(([_, text]) => text !== '-')
          .map(([id, text]) => ({
            id,
            text: translateOptions(text),
            value: text
          })));
        }
        
        const columnInteresPrograma = columns.find(col => col.id === 'selecci_n__nica6__1');
        if (columnInteresPrograma) {
          const settings = JSON.parse(columnInteresPrograma.settings_str);
          setOptionsInteresPrograma(Object.entries(settings.labels)
            .filter(([_, text]) => text !== '-')
            .map(([id, text]) => ({
              id,
              text: translateOptions(text),
              value: text
            })));
        }
        
        const columnComoNosConociste = columns.find(col => col.id === 'selecci_n__nica69__1');
        if (columnComoNosConociste) {
          const settings = JSON.parse(columnComoNosConociste.settings_str);
          setOptionsComoNosConociste(Object.entries(settings.labels)
            .filter(([_, text]) => text !== '-')
            .map(([id, text]) => ({
              id,
              text: translateOptions(text),
              value: text
            })));
        }
        
        const columnIdioma = columns.find(col => col.id === 'selecci_n__nica1__1');
        if (columnIdioma) {
          const settings = JSON.parse(columnIdioma.settings_str);
          setOptionsIdioma(Object.entries(settings.labels)
            .filter(([_, text]) => text !== '-')
            .map(([id, text]) => ({
              id,
              text: translateOptions(text),
              value: text
            })));
        }

        setLoadingOptions(false);
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
        setError('Hubo un error al cargar las opciones.');
        setLoadingOptions(false);
      });
    }, [apiKey, i18n.language]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFieldErrors({
      ...fieldErrors,
      [name]: false
    });
  };
  

  const handleDateChange = (date, fieldName) => {
    setFormData({ ...formData, [fieldName]: date });
  };

  
  const nextStep = () => {
    const errors = {};
    const errorMessages = {};
    
    if (currentStep === 1) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
      if (!emailRegex.test(formData.email)) {
        errors.email = true;
        errorMessages.email = "Por favor ingresa un email válido";
      }
      
      if (!formData.relacion_jugador) {
        errors.relacion_jugador = true; 
        errorMessages.relacion_jugador = "Por favor selecciona una opción";
      }

      if (!formData.numero_telefono) {
        errors.numero_telefono = true;
        errorMessages.numero_telefono = "Este campo es requerido";
       } else if (!/^[0-9+]+$/.test(formData.numero_telefono)) {
        errors.numero_telefono = true;
        errorMessages.numero_telefono = "Solo se permiten números";
       }

       if (!formData.codigo_area) {
        errors.codigo_area = true;
        errorMessages.codigo_area = "Este campo es requerido";
       }
       
      
      if (formData.relacion_jugador !== "3") {
        if (!formData.nombre_contacto) {
          errors.nombre_contacto = true;
          errorMessages.nombre_contacto = "Este campo es requerido";
        }
        if (!formData.apellido_contacto) {
          errors.apellido_contacto = true;
          errorMessages.apellido_contacto = "Este campo es requerido";
        }
      }
      
      if (Object.keys(errors).length > 0) {
        setFieldErrors(errors);
        setErrorMessages(errorMessages);
        return;
      }
    }
   
    if (currentStep === 2) {
      if (!formData.nombre_jugador) {
        errors.nombre_jugador = true;
        errorMessages.nombre_jugador = "Este campo es requerido";
      }
      if (!formData.apellido_jugador) {
        errors.apellido_jugador = true; 
        errorMessages.apellido_jugador = "Este campo es requerido";
      }
      if (!formData.fecha_nacimiento) {
        errors.fecha_nacimiento = true;
        errorMessages.fecha_nacimiento = "Por favor selecciona una fecha";
      }
      if (!formData.genero) {
        errors.genero = true;
        errorMessages.genero = "Por favor selecciona una opción";
      }
      if (!formData.tipo_jugador) {
        errors.tipo_jugador = true;
        errorMessages.tipo_jugador = "Por favor selecciona una opción";
      }
      
      if (Object.keys(errors).length > 0) {
        setFieldErrors(errors);
        setErrorMessages(errorMessages);
        return;
      }
    }
   
    if (currentStep === 3) {
      if (!formData.interes_programa) {
        errors.interes_programa = true;
        errorMessages.interes_programa = "Por favor selecciona una opción";
      }
      if (!formData.fecha_inicio) {
        errors.fecha_inicio = true;
        errorMessages.fecha_inicio = "Por favor selecciona una fecha";
      }
      if (!formData.idioma_preferido) {
        errors.idioma_preferido = true;
        errorMessages.idioma_preferido = "Por favor selecciona un idioma";
      }
      if (!formData.pais_origen) {
        errors.pais_origen = true;
        errorMessages.pais_origen = "Por favor selecciona un país";
      }
      if (!formData.como_conociste) {
        errors.como_conociste = true;
        errorMessages.como_conociste = "Por favor selecciona una opción";
      }
      
      if (Object.keys(errors).length > 0) {
        setFieldErrors(errors);
        setErrorMessages(errorMessages);
        return;
      }
    }
   
    setFieldErrors({});
    setErrorMessages({});
    setCurrentStep(currentStep + 1);
   };
  
  const prevStep = () => setCurrentStep(currentStep - 1);

  const getCurrentLanguage = () => {
    const host = window.location.host;
    if (host.includes('marcetfootball-form-en')) {
      return 'en';
    } else if (host.includes('marcetfootball-form-fr')) {
      return 'fr';
    } else {
      return 'es';
    }
  };

  const getUrlAndUtms = () => {
    try {
      const url = new URL(window.location.href);
      const utmSource = url.searchParams.get('parent_utm_source');
      const utmCampaign = url.searchParams.get('parent_utm_campaign');
      const parentPath = url.searchParams.get('parent_path') || '/';
      
      console.log("1. URL actual:", window.location.href);
      console.log("2. Path recibido:", parentPath);

      const data = {};
      
      if (document.referrer) {
        const refererUrl = new URL(document.referrer);
        data.url__1 = refererUrl.hostname + parentPath;
      } else {
        data.url__1 = window.location.hostname + parentPath;
      }
      console.log("3. URL final construida:", data.url__1);

      if (utmSource && utmSource !== 'null') data.utm_medio__1 = utmSource;
      if (utmCampaign && utmCampaign !== 'null') data.utm_campa_a__1 = utmCampaign;
  
      console.log("4. Objeto data final:", data);
      return data;
    } catch (error) {
      console.error("Error:", error);
      return {};
    }
};
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const columnValues = {
        relaci_n_con_jugador5__1: options.find(option => option.id === formData.relacion_jugador)?.value,
        selecci_n__nica5__1: optionsGenero.find(option => option.id === formData.genero)?.value,
        selecci_n__nica9__1: optionsTipoJugador.find(option => option.id === formData.tipo_jugador)?.value,
        selecci_n__nica6__1: optionsInteresPrograma.find(option => option.id === formData.interes_programa)?.value,
        selecci_n__nica69__1: optionsComoNosConociste.find(option => option.id === formData.como_conociste)?.value,
        selecci_n__nica1__1: optionsIdioma.find(option => option.id === formData.idioma_preferido)?.value,

        texto_corto5__1: formData.nombre_contacto,
        texto_corto8__1: formData.apellido_contacto,
        texto_corto9__1: formData.segundo_apellido_contacto,
        lead_phone: formData.codigo_area && formData.numero_telefono && formData.codigoPais
        ? `${formData.codigo_area}${formData.numero_telefono} ${formData.codigoPais}`
        : null,      
        lead_email: {
          email: formData.email,
          text: formData.email
        },

        ...getUrlAndUtms(),
        
        status7__1: getCurrentLanguage() === 'es' ? 'Español' :
        getCurrentLanguage() === 'fr' ? 'Frances' : 'Ingles',
        
        texto_corto4__1: formData.nombre_jugador,
        texto_corto1__1: formData.apellido_jugador,
        texto_corto49__1: formData.segundo_apellido_jugador,
        fecha__1: formData.fecha_nacimiento 
          ? formData.fecha_nacimiento.toISOString().split('T')[0] 
          : null,


        fecha5__1: formData.fecha_inicio 
          ? formData.fecha_inicio.toISOString().split('T')[0]
          : null,
        pa_s6__1: formData.pais_origen
        ? {
            countryCode: formData.pais_origen.countryCode,
            countryName: formData.pais_origen.countryName
          }
        : null
      };

      Object.keys(columnValues).forEach(key => 
        columnValues[key] === null && delete columnValues[key]
      );

      console.log('Valor de idioma:', formData.idioma_preferido);
      console.log('Opciones de idioma:', optionsIdioma);
      console.log('Column Values completo:', columnValues);

      const mutation = `
        mutation {
          create_item(
            board_id: 1479621461,
            group_id: "grupo_nuevo85897__1",
            item_name: "${formData.nombre_jugador} ${formData.apellido_jugador}",
            column_values: ${JSON.stringify(JSON.stringify(columnValues))}
          ) {
            id
          }
        }
      `;

      const response = await axios.post(
        'https://api.monday.com/v2',
        { query: mutation },
        {
          headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('Submit response:', response.data);

      toast.success('¡Formulario enviado con éxito!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormData({
        name: '',
        relacion_jugador: '',
        nombre_contacto: '',
        apellido_contacto: '',
        codigo_area: '',
        numero_telefono: '',
        email: '',
        nombre_jugador: '',
        apellido_jugador: '',
        fecha_nacimiento: null,
        genero: '',
        tipo_jugador: '',
        interes_programa: '',
        fecha_inicio: null,
        idioma_preferido: '',
        pais_origen: '',
        como_conociste: '',
      });
      setCurrentStep(1);
      
    } catch (error) {
      console.error('Submit error:', error);
      setError('Error al enviar los datos. Por favor, inténtalo de nuevo.');
      toast.error('Hubo un error al enviar el formulario. Intenta nuevamente.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  };
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="formulario">
        <h2>{t('title')}</h2>
        <hr />
        {currentStep === 1 && (
          <>
            <div className="form-group">
              <select
                id="relacion_jugador"
                name="relacion_jugador"
                value={formData.relacion_jugador}
                onChange={handleChange} 
                className={fieldErrors.relacion_jugador ? 'error-input' : ''}
                required
              >
                <option value="" disabled>{t('form.relation')}</option>
                {options.map((option) => (
                  <option key={option.id} value={option.id}>{option.text}</option>
                ))}
              </select>
              {fieldErrors.relacion_jugador && (
                <span className="error-message">{t('errors.selectOption')}</span>
              )}
            </div>
   
            {formData.relacion_jugador !== "3" && (
              <>
                <div className="inline-inputs">
                  <div className="form-group">
                    <input
                      type="text"
                      name="nombre_contacto"
                      value={formData.nombre_contacto}
                      onChange={handleChange}
                      className={fieldErrors.nombre_contacto ? 'error-input' : ''}
                      required
                      placeholder={t('form.requesterFirstName')}
                    />
                    {fieldErrors.nombre_contacto && (
                      <span className="error-message">{t('errors.required')}</span>
                    )}
                  </div>
   
                  <div className="form-group">
                    <input
                      type="text"
                      name="apellido_contacto"
                      value={formData.apellido_contacto}
                      onChange={handleChange}
                      className={fieldErrors.apellido_contacto ? 'error-input' : ''}
                      required
                      placeholder={t('form.requesterLastName')}
                    />
                    {fieldErrors.apellido_contacto && (
                      <span className="error-message">{t('errors.required')}</span>
                    )}
                  </div>
                </div>
   
                <div className="form-group">
                  <input
                    type="text"
                    name="segundo_apellido_contacto"
                    value={formData.segundo_apellido_contacto}
                    onChange={handleChange}
                    placeholder={t('form.secondRequesterLastName')}
                  />
                </div>
              </>
            )}
   
            <div className="form-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder={t('form.email')}
                className={fieldErrors.email ? 'error-input' : ''}
                required
              />
              {fieldErrors.email && (
                <span className="error-message">{t('errors.invalidEmail')}</span>
              )}
            </div>
   
            <div className="inline-inputs">
              <div className="codigo-area phone-field">
                <Select
                  id="codigo_area"
                  name="codigo_area"
                  value={formData.codigo_area ? areaOptions.find(option => option.value === formData.codigo_area) : null}
                  onChange={(e) => {
                    const selectedCountry = countryCodes.find(country => country.dial_code === e.value);
                    setFormData({
                      ...formData,
                      codigo_area: e.value,
                      codigoPais: selectedCountry ? selectedCountry.code : null
                    });
                  }}
                  options={areaOptions}
                  isSearchable
                  className={`react-select-container ${fieldErrors.codigo_area ? 'error-input' : ''}`}
                  classNamePrefix="react-select"
                  placeholder={t('form.areaCode')}
                />
                {fieldErrors.codigo_area && (
                  <span className="error-message">{t('errors.required')}</span>
                )}
              </div>
   
              <div className="numero-telefono">
                <input
                  type="tel"
                  name="numero_telefono"
                  value={formData.numero_telefono}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9+]*$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  placeholder={t('form.phone')}
                  className={fieldErrors.numero_telefono ? 'error-input' : ''}
                  required
                />
                {fieldErrors.numero_telefono && (
                  <span className="error-message">{t('errors.required')}</span>
                )}
              </div>
            </div>
   
            <hr />
            <div className="button-group">
              <button type="button" className={`left ${currentStep === 1 ? 'oculto' : ''}`} onClick={prevStep}>
                {t('buttons.back')}
              </button>
              <button type="button" className="right" onClick={nextStep}>
                {t('buttons.next')}
              </button>
            </div>
          </>
        )}
   
        {/* Step 2 */}
        {currentStep === 2 && (
          <>
            <div className="inline-inputs">
              <div className="form-group">
                <input
                  type="text"
                  name="nombre_jugador"
                  value={formData.nombre_jugador}
                  onChange={handleChange}
                  className={fieldErrors.nombre_jugador ? 'error-input' : ''}
                  required
                  placeholder={t('form.playerFirstName')}
                />
                {fieldErrors.nombre_jugador && (
                  <span className="error-message">{t('errors.required')}</span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="apellido_jugador"
                  value={formData.apellido_jugador}
                  onChange={handleChange}
                  className={fieldErrors.apellido_jugador ? 'error-input' : ''}
                  required
                  placeholder={t('form.playerLastName')}
                />
                {fieldErrors.apellido_jugador && (
                  <span className="error-message">{t('errors.required')}</span>
                )}
              </div>
            </div>
   
            <div className="form-group">
              <input
                type="text"
                name="segundo_apellido_jugador"
                value={formData.segundo_apellido_jugador}
                onChange={handleChange}
                placeholder={t('form.secondLastName')}
              />
            </div>
   
            <div className="form-group">
              <label>{t('form.birthDate')}</label>
              <SimpleDatePicker
                selected={formData.fecha_nacimiento}
                onChange={(date) => handleDateChange(date, 'fecha_nacimiento')}
                className={fieldErrors.fecha_nacimiento ? 'error-input' : ''}
                max={new Date().toISOString().split('T')[0]}
                placeholderText={t('form.birthDate')}
              />
              {fieldErrors.fecha_nacimiento && (
                <span className="error-message">{t('errors.selectDate')}</span>
              )}
            </div>
   
            <div className="form-group">
              <select
                name="genero"
                value={formData.genero}
                onChange={handleChange}
                className={fieldErrors.genero ? 'error-input' : ''}
                required
              >
                <option value="">{t('form.gender')}</option>
                {optionsGenero.map((option) => (
                  <option key={option.id} value={option.id}>{option.text}</option>
                ))}
              </select>
              {fieldErrors.genero && (
                <span className="error-message">{t('errors.selectOption')}</span>
              )}
            </div>
   
            <div className="form-group">
              <select
                name="tipo_jugador"
                value={formData.tipo_jugador}
                onChange={handleChange}
                className={fieldErrors.tipo_jugador ? 'error-input' : ''}
                required
              >
                <option value="" disabled>{t('form.playerType')}</option>
                {optionsTipoJugador.map(option => (
                  <option key={option.id} value={option.id}>{option.text}</option>
                ))}
              </select>
              {fieldErrors.tipo_jugador && (
                <span className="error-message">{t('errors.selectOption')}</span>
              )}
            </div>
   
            <hr />
            <div className="button-group">
              <button type="button" className="left" onClick={prevStep}>
                {t('buttons.back')}
              </button>
              <button type="button" className="right" onClick={nextStep}>
                {t('buttons.next')}
              </button>
            </div>
          </>
        )}
   
        {/* Step 3 */}
        {currentStep === 3 && (
          <>
            <div className="form-group">
              <select
                name="interes_programa"
                value={formData.interes_programa}
                onChange={handleChange}
                className={fieldErrors.interes_programa ? 'error-input' : ''}
                required
              >
                <option value="">{t('form.programInterest')}</option>
                {optionsInteresPrograma.map(option => (
                  <option key={option.id} value={option.id}>{option.text}</option>
                ))}
              </select>
              {fieldErrors.interes_programa && (
                <span className="error-message">{t('errors.selectOption')}</span>
              )}
            </div>
   
            <div className="form-group">
              <label>{t('form.startDate')}</label>
              <SimpleDatePicker
                selected={formData.fecha_inicio}
                onChange={(date) => handleDateChange(date, 'fecha_inicio')}
                className={fieldErrors.fecha_inicio ? 'error-input' : ''}
                min={new Date().toISOString().split('T')[0]}
                required
                placeholderText={t('form.startDate')}
              />
              {fieldErrors.fecha_inicio && (
                <span className="error-message">{t('errors.selectDate')}</span>
              )}
            </div>
   
            <div className="form-group">
              <select
                name="idioma_preferido"
                value={formData.idioma_preferido}
                onChange={handleChange}
                className={fieldErrors.idioma_preferido ? 'error-input' : ''}
                required
              >
                <option value="">{t('form.preferredLanguage')}</option>
                {optionsIdioma.map((option) => (
                  <option key={option.id} value={option.id}>{option.text}</option>
                ))}
              </select>
              {fieldErrors.idioma_preferido && (
                <span className="error-message">{t('errors.selectOption')}</span>
              )}
            </div>
   
            <div className="form-group">
              <Select
                name="pais_origen"
                value={countryOptions.find((option) => 
                  option.value.countryCode === formData.pais_origen?.countryCode
                )}
                onChange={handleCountryChange}
                options={countryOptions}
                isSearchable
                className={`react-select-container ${fieldErrors.pais_origen ? 'error-input' : ''}`}
                classNamePrefix="react-select"
                placeholder={t('form.country')}
                required
              />
              {fieldErrors.pais_origen && (
                <span className="error-message">{t('errors.selectOption')}</span>
              )}
            </div>
   
            <div className="form-group">
              <select
                name="como_conociste"
                value={formData.como_conociste}
                onChange={handleChange}
                className={fieldErrors.como_conociste ? 'error-input' : ''}
                required
              >
                <option value="">{t('form.howFound')}</option>
                {optionsComoNosConociste.map(option => (
                  <option key={option.id} value={option.id}>{option.text}</option>
                ))}
              </select>
              {fieldErrors.como_conociste && (
                <span className="error-message">{t('errors.selectOption')}</span>
              )}
            </div>
   
            <hr />
            <div className="button-group">
              <button type="button" className="left" onClick={prevStep}>
                {t('buttons.back')}
              </button>
              <button type="submit" className="right" disabled={loading}>
                {t('buttons.submit')}
              </button>
            </div>
          </>
        )}
      </form>
      <ToastContainer />
    </div>
   );
};

export default Formulario;