import React from 'react';

const SimpleDatePicker = ({ selected, onChange, min, max, className, required }) => {
  return (
    <div className="date-picker-container">
      <input
        type="date"
        value={selected ? new Date(selected).toISOString().split('T')[0] : ''}
        onChange={(e) => onChange(e.target.value ? new Date(e.target.value) : null)}
        className={`date-input ${className || ''}`}
        min={min}
        max={max}
        required={required}
      />
    </div>
  );
};

export default SimpleDatePicker;